<template>
  <div v-if="$grantAccess($me, 'updateAny', 'risk-matrix')">
    <v-layout v-if="!revisionsFetched" justify-end>
      <v-btn small outlined color="primary lighten-1" @click="setupRevisions" :loading="revisionsPending" :disabled="dataPending || revisionsPending">
        <v-icon left>history</v-icon>
        Revisions
      </v-btn>
    </v-layout>

    <template v-else-if="hasRevisions">
      <span>Revisions</span>
      <v-layout>
        <v-slider
          class="pibot-revision-slider"
          v-model="curRevisionStep"
          color="primary darken-1"
          track-color="primary darken-1"
          ticks="always"
          tick-size="4"
          :tick-labels="tickLabels"
          :step="100 / (revisionsAndNow.length - 1)"
          @change="changedRevSelect"
          :disabled="dataPending"
          :thumb-label="false"
        />
      </v-layout>
    </template>

    <template v-else>
      <span>No revisions available</span>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import { matrixMixin } from '../utils/mixins/matrix.mixin'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'pibot-asset-risk-matrix-revisions',
  mixins: [matrixMixin],
  data () {
    return {
      curRevisionStep: null,
      dataCache: null
    }
  },
  computed: {
    ...mapState({
      dataPending: state => state.matrix.dataPending,
      revisionsPending: state => state.matrix.revisionsPending,
      revisionsFetched: state => state.matrix.revisionsFetched,
      revisions: state => state.matrix.revisions,
      revisionData: state => state.matrix.revisionData,
      items: state => state.matrix.rows
    }),
    hasRevisions () {
      return this.revisions.length > 0
    },
    tickLabels () {
      const tickLabels = this.revisions.map(r => {
        let format = 'D MMM YY HH:mm'
        if (this.inThisDay(r)) format = 'ddd HH:mm'
        else if (this.inThisWeek(r)) format = 'ddd HH:mm'
        else if (this.inThisMonth(r)) format = 'ddd D HH:mm'
        else if (this.inThisYear(r)) format = 'D MMM HH:mm'

        return moment(r).format(format)
      })

      tickLabels.push('Now')

      return tickLabels
    },
    revisionsAndNow () {
      const revisions = JSON.parse(JSON.stringify(this.revisions))
      revisions.push(parseInt(moment().format('x')))
      return revisions
    }
  },
  methods: {
    ...mapMutations({
      setTableData: 'matrix/setRows',
      setRevisionsFetchedState: 'matrix/setRevisionsFetchedState',
      setIsRevisionDataState: 'matrix/setIsRevisionDataState'
    }),
    ...mapActions({
      fetchRevisions: 'matrix/socket_fetchRevisions',
      fetchRevisionData: 'matrix/socket_fetchRevisionData'
    }),
    setupRevisions () {
      this.fetchRevisions()
        .then(() => {
          if (this.hasRevisions) {
            // Set the current step to one before 'Now'
            const steps = 1
            /**
             * Calculate a number from 0 - 100 to set the slider step, starting from the end.
             * Divides 100 by the length of all options on the slider.
             * This gets multiplied by the length of all options excluding 'Now'
             * and the number of steps.
             */
            this.curRevisionStep = (100 / (this.revisionsAndNow.length - 1)) * (this.revisionsAndNow.length - 1 - steps)
            this.changedRevSelect(this.curRevisionStep)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
    changedRevSelect (newValue) {
      // Find index of corresponding timestamp
      const tsIndex = this.revisionsAndNow.findIndex((r, i) => {
        /**
         * Returns true if,
         * the current index is the last item,
         * or
         * 'newValue' is equal to 100 divided by the number of options, excluding 'Now',
         * multiplied by the current index in the loop.
         */
        return i === (this.revisionsAndNow.length - 1) || newValue === (100 / (this.revisionsAndNow.length - 1)) * i
      })

      // If this is the last item ('Now'), revert to the cache
      if (tsIndex === this.revisionsAndNow.length - 1) {
        this.setIsRevisionDataState(false)
        this.processRevisionData(this.dataCache)
        return
      }

      this.setIsRevisionDataState(true)
      const timestamp = this.revisions[tsIndex]

      if (!timestamp) return console.error(`timestamp: ${timestamp}`)

      // find corresponding data
      const revisionData = this.revisionData[timestamp]

      if (revisionData) { // Revision data for selected timestamp available, continue as usual
        this.processRevisionData(revisionData)
      } else { // No data available, fetch it first
        this.fetchRevisionData(timestamp)
          .then(this.processRevisionData)
          .catch((error) => {
            console.error(error)
          })
      }
    },
    processRevisionData (revisionData) {
      if (!this.dataCache) this.dataCache = JSON.parse(JSON.stringify(this.items))
      this.setTableData(revisionData)
      this.incrChanges()
    },
    inThisYear (timestamp) {
      return moment().format('YYYY') === moment(timestamp).format('YYYY')
    },
    inThisMonth (timestamp) {
      return moment().format('M') === moment(timestamp).format('M')
    },
    inThisWeek (timestamp) {
      return moment().format('W') === moment(timestamp).format('W')
    },
    inThisDay (timestamp) {
      return moment().format('DDD') === moment(timestamp).format('DDD')
    }
  }
}
</script>
