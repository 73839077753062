import { mapState, mapMutations } from 'vuex'

export const matrixMixin = {
  computed: {
    ...mapState({
      numChanges: state => state.matrix.numChanges
    })
  },
  methods: {
    ...mapMutations({
      incrChanges: 'matrix/incrChanges',
      setNumChanges: 'matrix/setNumChanges'
    })
  }
}
